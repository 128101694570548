<div
  *ngIf="showTemplate"
  class="flex justify-center h-screen w-full items-center"
>
  <div class="lg:w-[60%] flex justify-center flex-col items-center relative">
    <span
      class="absolute text-[120px] font-extrabold text-primary/5 -z-10 top-6"
      >THANK YOU !</span
    >
    <img class="w-[200px]" src="/assets/payment/card-payment.svg" alt="" />
    <h1 class="font-poppins text-[24px] font-semibold text-primary/80 mt-6">
      Your Appointment is Success.
    </h1>
    <p class="mt-4">Thank for your appointment.</p>
    <p class="text-green-500 font-semibold">{{ message }}</p>
    <!--An automated payment recept will be sent your email or phone number-->
    <div class="mt-7">
      <button class="btn-primary" routerLink="/">Back to Home</button>
      <button
        class="btn-primary"
        [routerLink]="'/' + userType + '/appointments'"
      >
        {{ isLoading ? "Loading" : "See Appointments" }}
      </button>
    </div>
  </div>
</div>
