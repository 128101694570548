<div class="md:py-[60px] py-[50px] w-full bg-gray-50">
  <div class="container h-full">
    <div class="flex flex-col justify-center h-full">
      <app-section-title [titleData]="titleData" />

      @if (isLoading) {
      <div
        class="relative w-full justify-items-stretch grid gap-7 md:mt-16 mt-5 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
      >
        @for (_ of [1, 2, 3, 4]; track $index) {
        <app-skeleton></app-skeleton>

        }
      </div>
      }@else {
      <div
        id=" py-6"
        class="relative w-full justify-items-stretch grid gap-7 md:mt-10 mt-5 grid-cols-1 md:grid-cols-2 xl:grid-cols-3"
      >
        @for (details of liveDoctorList;let idx = $index; track details.id) {
        <app-live-doctor-card
          [doctorDetails]="details"
          class="w-full flex justify-center"
        ></app-live-doctor-card>
        }
      </div>
      }

      <button
        *ngIf="liveDoctorList?.length > 0"
        class="rounded-3xl mt-10 px-6 py-2 bg-secondary text-white mx-auto"
        (click)="onClickSeeMore('Instant')"
      >
        see more
      </button>
    </div>
  </div>
  <!--<div class="w-full flex justify-center">
    <mat-paginator class="bg-primary/5 w-fit py-2 px-2.5 rounded-xl shadow-2xl shadow-secondary/10"
                   [length]="totalCount"
                   [pageSize]="filterModel.pageSize"
                   [pageIndex]="filterModel.pageNo"
                   aria-label="Select page"
                   (page)="pageChanged($event)">
    </mat-paginator>
  </div>-->
</div>
