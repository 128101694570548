import { Component } from '@angular/core';

@Component({
  selector: 'app-equipments',
  standalone: true,
  imports: [],
  templateUrl: './equipments.component.html',
  styleUrl: './equipments.component.scss'
})
export class EquipmentsComponent {

}
