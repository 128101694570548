import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // isLoading: boolean = false;
  // key: any = 'soowgood2024';
  // userInfo: any;
  // constructor(
  //   private loaderService: LoaderService,
  //   private cdr: ChangeDetectorRef
  // ) {}
  // ngOnInit() {
  // this.requestPermission();
  // this.listen();
  // this.loaderService.getLoaderState().subscribe((s) => {
  //   this.isLoading = s;
  //   this.cdr.detectChanges();
  // });
  // }
  // title = 'af-notification';
  // message: any = null;
  // requestPermission() {
  //   const messaging = getMessaging();
  //   console.log('log', messaging);
  //   getToken(messaging, { vapidKey: environment['firebaseConfig'].vapidKey })
  //     .then((currentToken) => {
  //       if (currentToken) {
  //         console.log('Hurraaa!!! we got the token.....');
  //         console.log(currentToken);
  //       } else {
  //         console.log(
  //           'No registration token available. Request permission to generate one.'
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('An error occurred while retrieving token. ', err);
  //     });
  // }
  // listen() {
  //   const messaging = getMessaging();
  //   onMessage(messaging, (payload) => {
  //     console.log('Message received. ', payload);
  //     this.message = payload;
  //   });
  // }
}
