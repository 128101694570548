<div class="max-h-full flex relative overflow-hidden w-full bg-white">
  <div class="swiper w-full h-full">
    <div class="swiper-wrapper !w-full h-full">
      <div class="swiper-slide !w-full h-full" (click)="onClickService()">
        <img class="w-full h-full" src="/assets/banner/b3.jpg" alt="banner" />
      </div>
      <div class="swiper-slide !w-full h-full">
        <img class="w-full h-full" src="/assets/banner/b2.jpg" alt="banner" />
      </div>
      <div class="swiper-slide !w-full h-full">
        <img class="w-full h-full" src="/assets/banner/b1.jpg" alt="banner" />
      </div>
    </div>
    <div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
    </div>
  </div>
</div>
