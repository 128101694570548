<!-- Clients -->
<div class="bg-gray-50">
  <div class="container py-10 lg:py-14 mx-auto">
    <!-- Title -->

    <app-section-title [titleData]="titleData" />

    <!-- End Title -->

    <!-- Grid -->
    <div
      class="grid grid-cols-3 mt-10 sm:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7  2xl:grid-cols-9 gap-3 lg:gap-4"
    >
      <!-- @for (image of partnersLogo; track $index) {
      <app-partners-card [image]="image"></app-partners-card>
      } -->
      @for (details of companies; track $index) {
      <app-partners-card [details]="details"></app-partners-card>
      }
    </div>
    <!-- End Grid -->
  </div>

  <!-- End Clients -->
</div>
