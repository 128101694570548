import { Component } from '@angular/core';
import { TitleProps } from '../services/services.component';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrl: './partners.component.scss',
})
export class PartnersComponent {
  titleData: TitleProps = {
    title: 'Our Trusted Partners',
    subtitle: 'Partners',
  };

  // partnersLogo = [
  //   'assets/partners/WhatsApp Image 2/assets024-03-10 at 16.22.49_d5774e83.jpg',
  //   'assets/partners/WhatsApp Image 2/assets024-03-10 at 16.23.17_e6805db2.jpg',
  //   'assets/partners/WhatsApp Image 2/assets024-03-10 at 16.23.31_e271148a.jpg',
  //   'assets/partners/WhatsApp Image 2/assets024-03-10 at 16.23.41_a3ebd6cc.jpg',
  //   'assets/partners/WhatsApp Image 2/assets024-03-10 at 16.23.56_a1548473.jpg',
  //   'assets/partners/WhatsApp Image 2/assets024-03-10 at 16.24.07_bd4da4f9.jpg',
  //   'assets/partners/WhatsApp Image 2/assets024-03-10 at 16.25.02_5f2611fe.jpg',
  //   'assets/partners/WhatsApp Image 2/assets024-03-10 at 16.26.31_25613ff1.jpg',
  //   'assets/partners/WhatsApp Image 2/assets024-03-10 at 16.22.05_326c1568.jpg',
  //   'assets/partners/WhatsApp Image 2/assets024-03-10 at 16.23.01_6513ed64.jpg',
  // ];
  companies = [
    {
      companyName: 'Zaytoon',
      link: 'https://www.zaytoon.com.bd/',
      address:
        'Navana Sylvania (2nd Floor), Holding No- Ka-6/A, Nodda, Gulshan, Dhaka',
      contactPersons: [
        {
          name: 'Khademul Islam',
          designation: 'Head of Business',
          contactNumber: '1734764195',
        },
      ],
      image: '/assets/partners/Zaytoon.png',
      summaryOfContract: '',
      process: 'Partnership Done',
      status: 'Done',
    },
    {
      companyName: 'a2i',
      link: 'https://a2i.gov.bd/',
      address: '',
      contactPersons: [],
      summaryOfContract: '',
      process: '',
      status: '',
      image: '/assets/partners/A2i.png',
    },
    {
      companyName: 'Navana Pharmaceuticals Ltd',
      link: 'https://navanapharma.com/',
      address: '',
      contactPersons: [],
      summaryOfContract: '',
      process: 'Partnership Done',
      status: 'Done',
      image: '/assets/partners/Navana.png',
    },
    {
      companyName: 'Advance Hospital',
      link: 'https://advancehospitalltd.com/',
      address: 'House # 1, Main Road, Block # F, Banasree, Dhaka',
      contactPersons: [
        {
          name: 'Md. Anisur Rahman Jewel',
          designation: 'In-Charge (Business Development)',
          contactNumber: '1814271644',
        },
      ],
      summaryOfContract: '',
      process: 'Partnership Done',
      status: 'Done',
      image: '/assets/partners/Advance.png',
    },
    {
      companyName: 'Yogamrita Yoga Institute',
      link: 'https://www.yogamrita.info/',
      address:
        'House # 8/B Road # 28 (old) 15(new), Dhanmondi Residential Area, Dhaka',
      contactPersons: [
        { name: '', designation: 'Owner', contactNumber: '1732336695' },
      ],
      summaryOfContract: '',
      process: 'Partnership Done',
      status: 'Done',
      image: '/assets/partners/Yogamrita.png',
    },
    {
      companyName: 'Delta Health Care',
      link: 'https://www.deltahealthcarerampuraltd.com/',
      address: '',
      contactPersons: [],
      summaryOfContract: '',
      process: '',
      status: '',
      image: '/assets/partners/Delta.png',
    },
    {
      companyName: 'Prime Diagnostic Limited',
      link: 'https://www.primediagnosticltd.com/',
      address: 'Rupayan FPAB Tower, 2 Naya Paltan, Culvert Road, Dhaka-1000',
      contactPersons: [
        {
          name: 'Masuma Bilkis Shathy',
          designation: 'GM',
          contactNumber: '1552378322',
        },
      ],
      summaryOfContract: '',
      process: 'Partnership Done',
      status: 'Done',
      image: '/assets/partners/Prime.png',
    },
    {
      companyName: 'Care For You Nursing Home',
      link: 'https://nursingcareforyou.com.bd/',
      address: 'Lili Garden, 1511/2-A, Modhubag, Mogbazar, Dhaka- 1217',
      contactPersons: [
        {
          name: 'Md. Shojib',
          designation: 'Managing Director',
          contactNumber: '1754561226',
        },
      ],
      summaryOfContract: '',
      process: 'Partnership Done',
      status: 'Done',
      image: '/assets/partners/Nursing.png',
    },
    {
      companyName: 'Guardian Life Insurance',
      link: 'https://guardianlife.com.bd/',
      address:
        'Police Plaza Concord (13th Floor), Tower- 02, Plot # 02, Road # 144, Gulshan Avenue, Dhaka- 1212',
      contactPersons: [
        {
          name: 'Al-Amin',
          designation: 'Assistant Manager, Digital Channel & ADC Department',
          contactNumber: '1309015546',
        },
      ],
      summaryOfContract: '',
      process: 'Partnership Done',
      status: 'Done',
      image: '/assets/partners/Guardian.png',
    },
    {
      companyName: 'MedEasy',
      link: 'https://medeasy.health/',
      address: 'Road-05, House-06, Block-C, Banasree, Dhaka',
      contactPersons: [
        {
          name: 'Tawhidul Alam',
          designation: 'Head of Business',
          contactNumber: '1844671113',
        },
      ],
      summaryOfContract: '',
      process: 'Partnership Done',
      status: 'Done',
      image: '/assets/partners/Medeasy.png',
    },
    {
      companyName: 'Just Fit Health Care Private Ltd',
      link: 'https://www.justfithealthcarebd.com/',
      address:
        'Khan ABC Tradeplex, A5 - 5th Floor, House 37, Road 2, Dhanmondi, Dhaka',
      contactPersons: [
        {
          name: 'Jamal Uddin Ahmed',
          designation: 'Chairman',
          contactNumber: '1746649308',
        },
      ],
      summaryOfContract: '',
      process: 'Partnership Done',
      status: 'Done',
      image: '/assets/partners/justfit.png',
    },
    {
      companyName: 'Jony Ambulance Services/ Sasthya Seba',
      link: 'https://jonyambulanceservice.com/',
      address: 'House# 15, Road# 07, Block# C, Niketon, Gulshan-1, Dhaka',
      contactPersons: [
        { name: 'Md. Uzzal', designation: 'CEO', contactNumber: '1793162925' },
        {
          name: 'Md. Ali Mohsin',
          designation: 'Director',
          contactNumber: '1713388355',
        },
      ],
      summaryOfContract: 'All kinds of ambulance service',
      process: 'Partnership Done',
      status: 'Done',
      image: '/assets/partners/jony.png',
    },
    {
      companyName: 'Green Delta Insurance',
      link: 'https://green-delta.com/',
      address:
        'Green Delta Aims Tower (6th Floor), 51-52 Mohakhali , Dhaka-1212',
      contactPersons: [{ name: 'Ashique', designation: '', contactNumber: '' }],
      summaryOfContract: '',
      process: 'Partnership Done',
      status: 'Done',
      image: '/assets/partners/GreenDelta.png',
    },
    {
      companyName: 'Rushmono',
      link: 'https://www.facebook.com/RushmonoNetwork/',
      address: '',
      contactPersons: [],
      summaryOfContract: '',
      process: '',
      status: '',
      image: '/assets/partners/Rushmono.png',
    },
    {
      companyName: 'New Life Hospital (Pvt). Ltd.',
      link: 'https://www.facebook.com/newlifehospitalPvtLtd/',
      address: '25/A Green Road, Dhaka, Bangladesh-1205',
      contactPersons: [
        {
          name: 'Md. Abul Bashar Sarker',
          designation: 'Director & Chairman',
          contactNumber: '01819104376',
        },
        {
          name: 'Md. Abul Bashar Sarker',
          designation: 'Director & Chairman',
          contactNumber: '01709822709',
        },
      ],
      summaryOfContract: '',
      process: 'Partnership Done',
      status: 'Done',
      image: '/assets/partners/Newlife.png',
    },
  ];
}
