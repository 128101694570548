import {
  DoctorProfileService,
  DocumentsAttachmentService,
} from 'src/app/proxy/services';
import Swiper from 'swiper';
import { AuthService } from './../../../../../shared/services/auth.service';
// import { Navigation } from "swiper/modules";

import {
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, forkJoin, map, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FilterModel } from '../../../../../proxy/dto-models';
import { TitleProps } from '../services/services.component';

@Component({
  selector: 'app-live-doctors',
  templateUrl: './live-doctors.component.html',
  styleUrls: ['./live-doctors.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition('* => *', [
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [animate('500ms', style({ opacity: 1 }))]),
        ]),
      ]),
    ]),
  ],
})
export class LiveDoctorsComponent implements OnInit {
  public picUrl = `${environment.apis.default.url}/`;
  // swiper!: Swiper;
  liveDoctorList: any;
  isLoading: boolean = false;
  noData: boolean = false;
  swiper!: Swiper;

  filterModel: FilterModel = {
    offset: 0,
    limit: 0,
    pageNo: 1,
    pageSize: 6,
    sortBy: 'name',
    sortOrder: 'asc',
    isDesc: false,
  };

  titleData: TitleProps = {
    title:
      'Health at the Speed of Life Your Health, Our Priority, Anytime, Anywhere',
    subtitle: 'Available Instant Doctors',
  };
  userType: any;

  constructor(
    private DoctorProfileService: DoctorProfileService,
    private router: Router,
    private DocumentsAttachmentService: DocumentsAttachmentService,
    private AuthService: AuthService
  ) {}
  ngOnInit(): void {
    this.getDoctorDetails();
    this.userType = this.AuthService.authInfo()?.userType;
  }

  onClickSeeMore(value: string) {
    if (value) {
      this.router.navigate(['/search'], {
        queryParams: { consultancyType: value },
      });
    }
    return;
  }

  getDoctorDetails() {
    this.isLoading = true;
    this.liveDoctorList = [];

    this.filterModel.limit = this.filterModel.pageSize;
    this.filterModel.offset =
      (this.filterModel.pageNo - 1) * this.filterModel.pageSize;

    this.DoctorProfileService.getLiveOnlineDoctorList(this.filterModel)
      .pipe(
        switchMap((onlineDoctors: any) => {
          //descending order maintain from frontend
          // this.DoctorStateService.setInstantDoctorsWithImage(onlineDoctors);

          let sortedDoctors =
            this.userType !== 'agent'
              ? onlineDoctors.sort(
                  (a: any, b: any) =>
                    (b.displayInstantFeeAsPatient ?? 0) -
                    (a.displayInstantFeeAsPatient ?? 0)
                )
              : onlineDoctors.sort(
                  (a: any, b: any) =>
                    (b.displayInstantFeeAsAgent ?? 0) -
                    (a.displayInstantFeeAsAgent ?? 0)
                );
          // let sortedDoctors = onlineDoctors.sort(
          //   (a: any, b: any) =>
          //     (b.displayInstantFeeAsPatient ?? 0) -
          //     (a.displayInstantFeeAsPatient ?? 0)
          // );
          const profilePictureObservables = onlineDoctors.map((doctor: any) =>
            this.DocumentsAttachmentService.getDocumentInfoByEntityTypeAndEntityIdAndAttachmentType(
              'Doctor',
              doctor.id,
              'ProfilePicture'
            )
          );

          return forkJoin(profilePictureObservables).pipe(
            map((profilePictureResults: any) => {
              return sortedDoctors.map((doctor: any, index: any) => {
                const profilePicInfo = profilePictureResults[index];
                let profilePicUrl = '';

                if (profilePicInfo) {
                  const prePaths: string = profilePicInfo.path || '';
                  const re = /wwwroot/gi;
                  const profilePic = prePaths.replace(re, '');
                  profilePicUrl = this.picUrl + profilePic;
                }

                return {
                  ...doctor,
                  profilePicUrl: profilePicUrl,
                };
              });
            })
          );
        }),
        catchError((error) => {
          console.log(error);
          this.isLoading = false;
          return [];
        })
      )
      .subscribe({
        next: (doctorsWithPics) => {
          this.liveDoctorList = doctorsWithPics;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  }

  pageChanged($event: any) {
    this.filterModel.pageNo = $event?.pageIndex;
    this.getDoctorDetails();
  }
}
