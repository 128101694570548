<div class="section-spacing bg-white" id="services">
  <div class="container">
    <app-section-title [titleData]="titleData" />
    <div class="grid grid-cols-5 gap-10 mt-10">
      <div
        class="col-span-5 lg:col-span-3 grid grid-cols-3 gap-y-4 md:gap-y-5 md:gap-x-10 gap-4"
        [@slideInTop]="serviceDetails.length"
      >
        @for (item of serviceDetails; track $index) {
        <app-service-card [serviceDetails]="item" />
        }
      </div>
      <div class="hidden lg:block lg:col-span-2 mx-auto">
        <img class="max-w-[220px]" src="assets/service/no-1.svg" alt="no-1" />
      </div>
    </div>
  </div>
</div>
