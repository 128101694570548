import { Component, Input } from '@angular/core';
import { TitleProps } from '../services/services.component';

@Component({
  selector: 'app-section-title',

  templateUrl: './section-title.component.html',
  styleUrl: './section-title.component.scss',
})
export class SectionTitleComponent {
  @Input() titleData?: TitleProps;
}
