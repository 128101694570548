<div class="min-h-screen bg-gray-100">
  <div class="bg-primary h-[150px] text-white flex justify-center items-center">
    <h1 class="text-3xl font-bold text-center">Medical Equipment Suppliers</h1>
  </div>
  <div class="container py-10">
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <!-- fastpeoplescare.com Card -->
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="/assets/other/fast.png"
          alt="fastpeoplescare.com"
          class="w-full h-48 object-cover bg-red-900"
        />
        <div class="p-6">
          <h2 class="text-2xl font-bold text-gray-800 mb-2">
            fastpeoplescare.com
          </h2>
          <p class="text-gray-600 mb-4">Reliable medical equipment supplier</p>
          <a
            href="https://fastpeoplescare.com/index.php?function=category_shop&id=15"
            class="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
            target="_blank"
            >Visit Supplier</a
          >
        </div>
      </div>

      <!-- Add more supplier cards here if needed -->
    </div>
  </div>
</div>
