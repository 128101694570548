import { UserinfoStateService } from 'src/app/shared/services/states/userinfo-state.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  AppointmentService,
  PaymentHistoryService,
  SslCommerzService,
} from '../../../proxy/services';
import { TosterService } from '../../../shared/services/toster.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit, AfterViewInit {
  appCode: any;
  userType: string = '';
  isLoading: boolean = false;
  message: string = '';
  showTemplate: boolean = false;
  constructor(
    private appointmentService: AppointmentService,
    private tosterService: TosterService,
    private AuthService: AuthService,
    private Router: Router
  ) {}
  ngOnInit() {
    this.userType = this.AuthService.authInfo()?.userType;

    // Safely handle invalid or missing JSON in localStorage
    let patientAppointmentCode = localStorage.getItem('patientAppointmentCode');
    try {
      this.appCode = patientAppointmentCode
        ? JSON.parse(patientAppointmentCode)
        : null;
    } catch (error) {
      console.error('Invalid JSON in patientAppointmentCode:', error);
      this.appCode = null;
    }

    this.isLoading = true;

    if (!this.userType) {
      this.showTemplate = false;
      this.Router.navigate(['/']);
      return;
    }

    this.showTemplate = true;

    if (this.appCode) {
      this.appointmentService
        .updateAppointmentStatusAfterPayment(this.appCode, 1)
        .subscribe(
          (st) => {
            this.isLoading = false;
            this.message = 'Your appointment is confirmed!';
            console.log('remove');
            localStorage.removeItem('patientAppointmentCode');
          },
          (error) => {
            this.isLoading = false;
            this.tosterService.customToast(
              'Error updating appointment status',
              'error'
            );
          }
        );
    } else {
      this.Router.navigate(['/']);
    }
  }

  ngAfterViewInit(): void {
    if (!this.userType || !this.showTemplate) {
      this.tosterService.customToast(
        'You are not authorized to visit this page',
        'error'
      );
      this.Router.navigate(['/']);
    }
  }
}
