<div class="xl:max-w-[50%] md:max-w-[70%] lg:max-w-[60%]">
  <h2 class="text-secondary font-Roboto">
    {{ titleData?.subtitle }}
  </h2>
  <h1
    class="text-gray-600 max-w-[650px] font-semibold text-[20px] sm:text-[24px] md:text[28px] lg:text-[32px] font-poppins"
  >
    {{ titleData?.title }}
    <b class="text-secondary">{{ titleData?.blodText }}</b>
  </h1>
</div>
