<div class="min-h-screen bg-gray-100">
  <div class="h-[150px] bg-primary text-white flex items-center justify-center">
    <h1 class="text-3xl font-bold text-center">Pharmacies Near You</h1>
  </div>
  <div class="container py-10">
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
      <!-- Pharmacy Card 1 -->
      <div class="bg-white shadow-md rounded-lg overflow-hidden">
        <img
          src="assets/other/made.png"
          alt="Pharmacy 1"
          class="w-full h-48 object-cover"
        />
        <div class="p-6">
          <h2 class="text-2xl font-bold text-gray-800 mb-2">MedEasy</h2>
          <p class="text-gray-600 mb-4">Online pharmacy in Bangladesh</p>
          <a
            href="https://medeasy.health/"
            class="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
            >Visit Pharmacy</a
          >
        </div>
      </div>

      <!-- Add more pharmacy cards as needed -->
    </div>
  </div>
</div>
