<div class="flex justify-center h-screen w-full items-center">
  <div class="lg:w-[60%] flex justify-center flex-col items-center relative">
    <span
      class="absolute text-[120px] font-extrabold text-primary/5 -z-10 top-6"
      >OPS !</span
    >
    <img
      class="w-[200px]"
      width="250"
      height="250"
      ngSrc="/assets/payment/card-payment.svg"
      alt=""
    />
    <h1 class="font-poppins text-[24px] font-semibold text-primary/80 mt-6">
      Your Payment is Faild!
    </h1>
    <p class="mt-4">Please Contact to the administrator.</p>

    <div class="mt-7">
      <button class="btn-primary" routerLink="/">Back to Home</button>
    </div>
  </div>
</div>
