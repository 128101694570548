<div
  class="live-card p-4 md:p-6 shadow-xl shadow-secondary/10 border-secondary/50 group relative border-[1px] overflow-hidden w-full rounded-[10px] lg:min-h-[320px] bg-white"
>
  <div class="absolute top-6 right-8">
    @if(doctorDetails.isOnline && userType == 'agent' &&
    doctorDetails.displayInstantFeeAsAgent >= 0){
    <div>
      <h2
        class="text-[20px] md:text-[26px] xl:text-[30px] text-secondary font-poppinsBold m-0"
      >
        {{ Math.round(doctorDetails.displayInstantFeeAsAgent) }}
        <span class="font-normal text-[16px] font-poppins">tk.</span>
      </h2>
    </div>
    } @else if(doctorDetails.isOnline && userType == 'patient' &&
    doctorDetails.displayInstantFeeAsPatient >= 0){
    <div>
      <h2
        class="text-[20px] md:text-[26px] xl:text-[30px] text-secondary font-poppinsBold m-0"
      >
        {{ Math.round(doctorDetails.displayInstantFeeAsPatient) }}
        <span class="font-normal text-[16px] font-poppins">tk.</span>
      </h2>
    </div>
    } @else if(doctorDetails.displayInstantFeeAsPatient >= 0){
    <div>
      <h2
        class="text-[20px] md:text-[26px] xl:text-[30px] text-secondary font-poppinsBold m-0"
      >
        {{ Math.round(doctorDetails.displayInstantFeeAsPatient) }}
        <span class="font-normal text-[16px] font-poppins">tk.</span>
      </h2>
    </div>
    } @else {
    <div>
      <h2
        class="text-[20px] md:text-[26px] xl:text-[30px] text-secondary font-poppinsBold m-0"
      >
        {{ Math.round(doctorDetails.totalFee) }}
        <span class="font-normal text-[16px] font-poppins">tk.</span>
      </h2>
    </div>
    }
    <p class="text-[14px] font-poppinsLight -mt-2">Fee Inc. VAT</p>
  </div>

  <div class="w-full h-full z-10">
    <div class="flex flex-col w-full">
      <div
        class="w-[70px] h-[70px] lg:w-[90px] lg:h-[90px] relative mt-4 overflow-hidden border-secondary-dark/50 border-[1px] rounded-lg bg-secondary/10"
      >
        <img
          class="object-cover rounded-lg w-full h-full"
          [src]="doctorDetails.profilePicUrl || defaultImage"
          alt="doctor"
        />
      </div>

      <div class="mt-3 w-[85%] h-full">
        <h2
          class="text-[18px] lg:text-[20px] xl:text-[22px] font-poppins font-semibold text-primary/90"
        >
          <span class="text-[14px]">{{ doctorDetails.doctorTitleName }}</span>
          <br />
          {{ doctorDetails.doctorName }}
        </h2>
        <div class="h-[60px]">
          <p class="uppercase font-poppinsLight text-gray-500 text-[14px] mt-3">
            {{ doctorDetails.qualifications }}
          </p>
          <p class="font-poppinsLight text-gray-500 text-[14px] line-clamp-3">
            {{ doctorDetails.areaOfExperties }}
          </p>
        </div>

        <!-- <p class="font-Roboto font-normal text-gray-400 text-[14px]">
          Bangabandhu Sheikh Mujib Medical University
        </p> -->
      </div>
    </div>

    <!-- <div
      class="mt-7 relative font-poppins bg-secondary/5 border-secondary-dark/20 pt-3 border-[1px] grid rounded-lg w-full h-[50px] md:h-[70px] justify-center place-items-center text-center"
    >
      <span
        class="absolute rounded-full border-secondary-dark/20 px-4 text-[12px] sm:text-[14px] md:text-[15px] font-poppins text-secondary-dark border-[1px] bg-slate-50 backdrop:blur-lg -top-3 text-center min-w-[200px] left-1/2 -translate-x-1/2"
        >Doctor's Fee (inc. vat)</span
      >

      @if(doctorDetails.isOnline && userType == 'agent' &&
      doctorDetails.displayInstantFeeAsAgent >= 0){
      <div>
        <h2 class="text-[20px] md:text-[26px] xl:text-[30px] text-secondary font-poppins">
          {{ doctorDetails.displayInstantFeeAsAgent }} tk.
        </h2>
      </div>
      } @else if(doctorDetails.isOnline && userType == 'patient' &&
      doctorDetails.displayInstantFeeAsPatient >= 0){
      <div>
        <h2 class="text-[20px] md:text-[26px] xl:text-[30px] text-secondary font-poppins">
          {{ doctorDetails.displayInstantFeeAsPatient }} tk.
        </h2>
      </div>
      } @else if(doctorDetails.displayInstantFeeAsPatient >= 0){
      <div>
        <h2 class="text-[20px] md:text-[26px] xl:text-[30px] text-secondary font-poppins">
          {{ doctorDetails.displayInstantFeeAsPatient }} tk.
        </h2>
      </div>
      }
    </div> -->
    <div
      class="group-hover:border-t-secondary/50 box-border gap-3 w-full mt-5 flex justify-between"
    >
      <button
        (click)="goToProfile(this.doctorDetails.doctorProfileId)"
        class="px-4 font-semibold hover:bg-secondary/90 bg-gray-200 rounded-lg hover:text-white font-Roboto/80 text-white"
      >
        <i class="text-primary g fa-solid fa-user-tie text-[18px]"></i>
      </button>
      @if (userType !== 'doctor') {

      <button
        [disabled]="isLoading"
        (click)="onClickConsultNow(doctorDetails)"
        class="disabled:bg-gray-400 hover:bg-secondary/80 rounded-lg hover:text-white group-hover:border-r-secondary/50 flex-1 py-3 font-semibold font-Roboto/80 text-white active:scale-95 duration-200 bg-secondary"
      >
        {{ isLoading ? "Loading" : "Book Appointment" }}
      </button>
      }@else {
      <button
        (click)="goToProfile(this.doctorDetails.doctorProfileId)"
        class="px-4 font-semibold hover:bg-secondary/90 bg-gray-200 rounded-lg hover:text-white font-Roboto/80 text-white"
      >
        Profile
      </button>
      }
    </div>
  </div>
</div>
