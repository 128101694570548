import { Component } from '@angular/core';

@Component({
  selector: 'app-app-marketing',
  templateUrl: './app-marketing.component.html',
  styleUrls: ['./app-marketing.component.scss']
})
export class AppMarketingComponent {

}
