import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveDoctorCardComponent } from './live-doctor-card.component';

@NgModule({
  declarations: [LiveDoctorCardComponent],
  imports: [CommonModule],
  exports: [LiveDoctorCardComponent],
})
export class LiveDoctorCardModule {}
